import "./App.scss";
import Slider from "react-slick";
import { format } from "date-fns";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import Image from "./components/Image";
import icon_email from "./icons/icon_email.svg";
import icon_instagram from "./icons/icon_instagram.svg";
import { useState } from "react";
import AdminPanel from "./components/AdminPanel";
import { getBaseUrl } from "./helpers/getBaseUrl";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      de: {
        translation: {},
      },
      en: {
        translation: {},
      },
    },
    // lng: "de", // if you're using a language detector, do not define the lng option
    fallbackLng: "de",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

function App() {
  const [language, setLanguage] = useState(
    (navigator.language || navigator.userLanguage).substring(0, 2),
  );
  const { t, i18n } = useTranslation("", { lng: language });

  const [translationsFetched, setTranslationsFetched] = useState(false);
  const [eventsFetched, setEventsFetched] = useState(false);
  const [adminCounter, setAdminCounter] = useState(0);
  const [nextEvents, setNextEvents] = useState(false);

  const refreshTranslations = (translations = false) => {
    if (translations !== false) {
      // override from admin
      applyTranslations(translations);
    } else {
      // fetch from server
      const url = getBaseUrl(true) + "translations.json";

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          applyTranslations(json);
          setTranslationsFetched(true);
        });
    }
  };

  const applyTranslations = (translations) => {
    console.log("applying:", translations);

    const de = {};
    const en = {};

    Object.keys(translations).forEach((key) => {
      de[translations[key].key] = translations[key].de;
      en[translations[key].key] = translations[key].en;
    });

    i18n.addResourceBundle("de", "translation", de);
    i18n.addResourceBundle("en", "translation", en);
  };

  if (!translationsFetched) {
    refreshTranslations();
  }

  const refreshEvents = (events = false) => {
    if (events !== false) {
      // override from admin
      setNextEvents(events);
    } else {
      // fetch from server
      const url = getBaseUrl(true) + "events.json";

      fetch(url)
        .then((response) => response.json())
        .then((json) => {
          console.log("fetched events", json);
          setNextEvents(json);
        });
    }
  };

  if (!nextEvents) {
    refreshEvents();
  }

  // const events = [
  //   {
  //     title: "Kultursommer Wien",
  //     start: new Date("2023-08-10"),
  //     end: new Date("2023-08-11"),
  //     image: "impr1.jpg",
  //     link: "https://kultursommer.wien/act/?id=62595-voila-la-jeanine",
  //     location: "Mühlschüttelpark, 1210 Wien",
  //     dates: [
  //       {
  //         date: new Date("2023-08-10"),
  //         time: "20:00-21:00",
  //         location: "Wasserturm Favoriten, 1100 Wien",
  //         image: "impr1.jpg",
  //       },
  //       {
  //         date: new Date("2023-08-11"),
  //         time: "18:30-19:30",
  //         location: "Mühlschüttelpark, 1210 Wien",
  //         image: "impr1.jpg",
  //       },
  //     ],
  //   },
  // ];

  const eventsToRender = [];

  const eventsRaw = [];

  const dateTwoDaysAgo = new Date();
  dateTwoDaysAgo.setDate(dateTwoDaysAgo.getDate() - 2);
  console.log(dateTwoDaysAgo.toString());

  nextEvents &&
    Object.keys(nextEvents).forEach((key) => {
      if (nextEvents[key].date) {
        if (dateTwoDaysAgo - new Date(nextEvents[key].date) < 0) {
          eventsRaw.push(nextEvents[key]);
        }
      }
    });

  eventsRaw
    .sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    })
    .forEach((event, index) => {
      if (event.date) {
        const date = new Date(event.date);
        eventsToRender.push(
          <div className="event" key={`event_${index}`}>
            <div className="date">
              <div className="month">{format(date, "MMM")}</div>
              <div className="day">{format(date, "dd")}</div>
            </div>
            <div className="image">
              <Image src={event.image} width={250} />
            </div>
            <div className="content">
              <div className="headline">
                <h4>{event.title}</h4>
                <h5>{event.location}</h5>
              </div>
              <p>{event.time}</p>
              <div className="links">
                {event.location && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                  >
                    Google Maps
                  </a>
                )}

                {event.link && (
                  <a href={event.link} target="_blank" rel="noreferrer">
                    Link
                  </a>
                )}
              </div>
            </div>
          </div>,
        );
      }
    });

  // console.log(eventsToRender);

  const settingsPress = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsEvents = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={`App ${language}`}>
      <header className="App-header">
        <a href="#top" id="logo">
          Xpect
        </a>
        <ul className="links">
          <li>
            <a href="#ueber-uns">{t("nav_about")}</a>
          </li>
          <li>
            <a href="#team-info">{t("nav_team_info")}</a>
          </li>
          <li>
            <a href="#kontakt">{t("nav_contact")}</a>
          </li>
          {/*<li>*/}
          {/*  <a href="#shows">Shows</a>*/}
          {/*</li>*/}
          <li>
            <a href="#impressionen">{t("nav_impressions")}</a>
          </li>
        </ul>
        {/*<a className="nextShow" href="#shows">*/}
        {/*  <span className="title">Nächste Show</span>*/}
        {/*  <span className="date">{format(events[0].start, "dd.MM.")}</span>*/}
        {/*</a>*/}
        <ul className={"language"}>
          <li>
            <a
              href="en"
              onClick={(e) => {
                e.preventDefault();
                setLanguage(language === "de" ? "en" : "de");
              }}
            >
              {language === "de" ? "EN" : "DE"}
            </a>
          </li>
        </ul>
      </header>
      <div className="AppContent">
        <section className="contentblock hero">
          <div className="text">
            <div className="headline">
              <h1>Xpect</h1>
              <h2>{t("headline")}</h2>
            </div>
            <div className="content">
              <p>{t("hero_text")}</p>
              <a
                href="https://www.youtube.com/watch?v=5tKtDVJM4HM"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                {t("trailer_button")}
              </a>
            </div>
          </div>
          <div className="image">
            <Image src={"hero.jpg"} width={800} />
            <video
              src="videos/intro.mp4"
              poster={"images/hero.jpg"}
              autoPlay
              muted
              loop
              playsInline
              className="video-hero"
            />
          </div>
        </section>
        <Slider {...settingsPress} className="presse">
          <blockquote>
            <span className="de">
              „In ihrem Stück „Xpect“ überzeugen die beiden Grazer mit präzisen
              Handständen auf den Händen oder Füßen des anderen, mimikreicher
              Situationskomik wie spektakulären Figuren in der Luft, die die
              Schwere von Körpern aufzulösen scheinen“
            </span>
            <span className="en">
              “In their piece “Xpect”, the two people from Graz impress with
              their precision Handstands on each other's hands or feet, more
              facial expressions Situational comedy such as spectacular figures
              in the air that seem to dissolve the heaviness of bodies.”
            </span>

            <span className="author">Tina Perisutti – Kärntner Krone</span>
          </blockquote>

          <blockquote>
            <span className="de">
              „Die beiden schaffen einen Aufmerksamkeitsraum, in dem man nicht
              nur lacht und staunt, sondern auch den Atem anhält.“
            </span>
            <span className="en">
              “The two of them create a space for attention in which you can not
              only laughs and is amazed, but also holds your breath.”
            </span>

            <span className="author">
              Michaela Preiner –{" "}
              <a
                href="https://european-cultural-news.com/akrobatik-tanz-live-musik-und-eine-grosse-menge-an-humor/48523/"
                target="_blank"
                rel="noreferrer"
              >
                European Cultural News
              </a>
            </span>
          </blockquote>

          <blockquote>
            <span className="de">
              „Was ist schließlich aktueller als der in Frage stehende Übergang
              von Mensch/Lebewesen zu Objekt und umgekehrt; die Frage nach der
              Grenze und der der Ersetzbarkeit.“
            </span>
            <span className="en">
              “After all, what is more relevant than the transition in question
              from human/living being to object and vice versa; the question
              about the limit and that of replaceability.”
            </span>
            <span className="author">
              Eveline Koberg –{" "}
              <a
                href="https://www.tanz.at/index.php/kritiken/kritiken-2023/2766-dadasphaere"
                target="_blank"
                rel="noreferrer"
              >
                tanz.at
              </a>
            </span>
          </blockquote>

          <blockquote>
            <span className="de">
              „Die Shows sind auch von durchgängigem Humor durchzogen – für ein
              fast ständiges Lächeln, hin und wieder werden zusätzlich heftige
              Lacher hervorgerufen.“
            </span>
            <span className="en">
              “The shows are also infused with consistent humor – for one almost
              constant smiles, now and then even violent ones “Evoked laughter.”
            </span>

            <span className="author">
              Heinz Wagner -{" "}
              <a
                href="https://kijuku.at/buehne/dadasphaere-akrobatik-jonglage-musik-und-witz/"
                target="_blank"
                rel="noreferrer"
              >
                kiJuKU
              </a>
            </span>
          </blockquote>
        </Slider>
        <section className="contentblock about" id="ueber-uns">
          <div className="text">
            <h2>{t("about_us_headline")}</h2>
            <h3>{t("about_us_subline")}</h3>
            <p>{t("about_us_text")}</p>
          </div>
          <div className="image">
            <Image src={"ueber.jpg"} width={800} />
          </div>
        </section>
        <section className="contentblock">
          <div className="image">
            <Image src={"impr1.jpg"} width={800} />
          </div>
          <div className="image">
            <Image src={"impr2.jpg"} width={800} />
          </div>
        </section>
        <section className="contentblock team" id="team-info">
          <div className="image">
            <Image src={"team1.jpg"} width={800} />
          </div>
          <div className="image">
            <Image src={"team2.jpg"} width={800} />
          </div>
          <div className="text">
            <h2>{t("team_info_header")}</h2>
            <h3 className="">{t("team_info_subline")}</h3>
            <dl>
              <dt>{t("ti_1_label")}</dt>
              <dd>{t("ti_1_text")}</dd>

              <dt>{t("ti_2_label")}</dt>
              <dd>{t("ti_2_text")}</dd>

              <dt>{t("ti_3_label")}</dt>
              <dd>{t("ti_3_text")}</dd>

              <dt>{t("ti_4_label")}</dt>
              <dd>{t("ti_4_text")}</dd>

              <dt>{t("ti_5_label")}</dt>
              <dd>{t("ti_5_text")}</dd>

              <dt>{t("ti_6_label")}</dt>
              <dd>{t("ti_6_text")}</dd>

              <dt>{t("ti_7_label")}</dt>
              <dd>{t("ti_7_text")}</dd>
            </dl>
          </div>
        </section>
        <section className="contentblock kontakt" id="kontakt">
          <div className="contactBlock">
            <Image src={"193.jpg"} width={800} height={300} />
            <div className="text">
              <h2 className="">{t("contact_header")}</h2>
              <ul>
                <li>
                  <a href={`mailto:${t("email")}`}>
                    <img src={icon_email} alt="Email" width={24} height={24} />
                    {t("email")}
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.instagram.com/${t("ig_yasmine")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={icon_instagram}
                      alt="Instagram"
                      width={24}
                      height={24}
                    />
                    @Yasmine Heyer
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.instagram.com/${t("ig_uwe")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={icon_instagram}
                      alt="Instagram"
                      width={24}
                      height={24}
                    />
                    @Uwe Sattelkov
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="image">
            <Image src={"contact2.jpg"} width={800} />
          </div>
        </section>
        <section className="contentblock mt" id="shows">
          <h2>Nächste Shows</h2>
        </section>
        <Slider {...settingsEvents} className="events">
          {eventsToRender}
        </Slider>
        <section className="contentblock produktionen">
          <div className="text">
            <h2 className="">{t("further_productions_headline")}</h2>
            <p className="">{t("further_productions_text")}</p>

            <a href="#contakt">
              <Image src={"contakt_logo.png"} width={152} height={120} />
            </a>
          </div>

          <div className="image">
            <Image src={"ConTakt_AUX.jpg"} width={800} />
          </div>
        </section>
        <section className="contentblock mt" id="impressionen">
          <h2 className="">{t("impressions_headline")}</h2>
        </section>
        <section className="contentblock impressionen">
          <div className="image">
            <Image src={"173.jpg"} width={800} />
          </div>
          <div className="image">
            <Image src={"233.jpg"} width={800} />
          </div>
          <div className="image">
            <Image src={"008.jpg"} width={800} />
          </div>
        </section>
        <section className="contentblock impressionen">
          <div className="image">
            <Image src={"023.jpg"} width={800} />
          </div>
          <div className="image">
            <Image src={"169.jpg"} width={800} />
          </div>
          <div className="image">
            <Image src={"176.jpg"} width={800} />
          </div>
        </section>
        <section className="contentblock impressionen">
          <div className="image">
            <Image src={"178.jpg"} width={800} />
          </div>
          <div className="image">
            <Image src={"205.jpg"} width={800} />
          </div>
        </section>
        <section className="contentblock mt">
          <h2 className="">{t("sponsors_headline")}*innen</h2>
        </section>
        <section className="contentblock logos">
          <div className="logo">
            <Image src={"logo_bmk.png"} width={250} crop={false} />
          </div>
          <div className="logo">
            <Image src={"logo_graz.png"} width={250} crop={false} />
          </div>
          <div className="logo">
            <Image src={"logo_stmk.png"} width={250} crop={false} />
          </div>
          <div className="logo">
            <Image src={"logo_dasanderetheater.png"} width={250} crop={false} />
          </div>
          <div className="logo">
            <Image src={"logo_teichmann.png"} width={250} crop={false} />
          </div>
          <div className="logo">
            <Image src={"logo_tanzgraz.png"} width={250} crop={false} />
          </div>
        </section>
        <footer>
          <div className="content">
            <div
              className="logo"
              onClick={() => setAdminCounter(adminCounter + 1)}
            >
              <Image src={"logo_akrosphaere.png"} width={70} crop={false} />
            </div>
            <div className="text">
              <h4>XPect</h4>

              <p>Booking {t("email")}</p>

              <p>
                Verein Grazer Akrosphäre
                <br />
                Neuholdaugasse 69/6
                <br />
                8010 Graz
              </p>
            </div>
          </div>
        </footer>
      </div>

      {adminCounter > 4 ? (
        <AdminPanel
          updateTranslations={(translations) => applyTranslations(translations)}
        />
      ) : null}
    </div>
  );
}

export default App;
