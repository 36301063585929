import "./AdminPanel.scss";
import { useState } from "react";
import axios from "axios";
import TextareaAutosize from "react-textarea-autosize";
import { getBaseUrl } from "../helpers/getBaseUrl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const { Map } = require("immutable");

let timeOutId = false;
export default function AdminPanel({ updateTranslations }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [dirtyState, setDirtyState] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [editingMode, setEditingMode] = useState("events");
  const [datai18n, setDatai18n] = useState(Map());
  const [events, setEvents] = useState(Map());

  console.log("i18n", datai18n.toJS());
  console.log("events", events.toJS());

  if (datai18n.size === 0) {
    const url = getBaseUrl(true) + "translations.json";

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setDatai18n(Map(json));
      });
  }

  if (events.size === 0) {
    const url = getBaseUrl(true) + "events.json";

    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setEvents(Map(json));
      });
  }

  const login = (e) => {
    e.preventDefault();
    const url = getBaseUrl(true) + "admin/login";
    axios
      .post(url, {
        username: username,
        password: password,
      })
      .then((data) => {
        if (data.data.loginStatus) {
          setLoggedIn(true);
        }
      });
  };

  const saveTranslation = (newTranslation) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    setDirtyState(true);
    setDatai18n(newTranslation);
    updateTranslations(newTranslation.toJS());

    timeOutId = setTimeout(() => {
      console.log("saving");

      const url = getBaseUrl(true) + "admin/updateTranslations";
      axios
        .post(url, {
          username: username,
          password: password,
          translations: newTranslation.toJS(),
        })
        .then((data) => {
          if (data.data.success) {
            setDirtyState(false);
          }
        });
    }, 1000);
  };

  const saveEvent = (newEvent) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    setDirtyState(true);
    setEvents(newEvent);
    // updateTranslations(newEvent.toJS());

    timeOutId = setTimeout(() => {
      console.log("saving");

      const url = getBaseUrl(true) + "admin/updateEvents";
      axios
        .post(url, {
          username: username,
          password: password,
          events: newEvent.toJS(),
        })
        .then((data) => {
          if (data.data.success) {
            setDirtyState(false);
          }
        });
    }, 1000);
  };

  const addLineToTranslations = () => {
    const key = datai18n.size + 1;
    const newTranslation = datai18n.set(key, {
      sort: key,
      key: `key_${key}`,
      de: "NeuerText",
      en: "NewText",
    });

    saveTranslation(newTranslation);
  };

  const addEvent = () => {
    const key = Date.now();
    const newEvent = events.set(key, {
      // key: `key_${key}`,
      title: "Kultursommer Wien",
      date: new Date(),
      time: "",
      link: "",
      location: "",
      image: "impr1.jpg",
    });

    saveEvent(newEvent);
  };

  const changeTranslation = (key, value) => {
    const newTranslation = datai18n.setIn(key, value);
    saveTranslation(newTranslation);
  };

  const changeEvent = (key, value) => {
    const newEvent = events.setIn(key, value);
    console.log(newEvent.toJS());
    saveEvent(newEvent);
  };

  const deleteEvent = (key) => {
    const newEvent = events.delete(key);
    console.log(newEvent.toJS());
    saveEvent(newEvent);
  };

  const renderedTranslations = [];
  datai18n
    .sort((a, b) => {
      return a.sort - b.sort;
    })
    .forEach((data, key) => {
      renderedTranslations.push(
        <tr key={`translation_${key}`}>
          <td>
            <input
              type="text"
              value={data.key}
              className="readonly"
              onChange={(e) => changeTranslation([key, "key"], e.target.value)}
            />
          </td>
          <td>
            <TextareaAutosize
              value={data.de}
              onChange={(e) => changeTranslation([key, "de"], e.target.value)}
            />
          </td>
          <td>
            <TextareaAutosize
              value={data.en}
              onChange={(e) => changeTranslation([key, "en"], e.target.value)}
            />
          </td>
        </tr>,
      );
    });

  const renderedEvents = [];
  events.forEach((data, key) => {
    console.log("key", key);
    renderedEvents.push(
      <div className={"eventsList_Item"} key={`event_${key}`}>
        <div className={"label_combo"}>
          <label htmlFor={`event_title_${key}`}>Titel</label>
          <input
            name={`event_title_${key}`}
            type="text"
            value={data.title}
            className=""
            onChange={(e) => changeEvent([key, "title"], e.target.value)}
          />
        </div>
        <div className="label_combo">
          <label htmlFor={`event_date_${key}`}>Datum</label>
          <DatePicker
            name={`event_date_${key}`}
            selected={data.date}
            onChange={(date) => changeEvent([key, "date"], date)}
            dateFormat={"dd.MM.yyyy"}
          />
        </div>
        <div className={"label_combo"}>
          <label htmlFor={`event_time_${key}`}>Zeit</label>
          <input
            name={`event_time_${key}`}
            type="text"
            value={data.time}
            className=""
            onChange={(e) => changeEvent([key, "time"], e.target.value)}
          />
        </div>
        <div className={"label_combo"}>
          <label htmlFor={`event_link_${key}`}>Link</label>
          <input
            name={`event_link_${key}`}
            type="text"
            value={data.link}
            className=""
            onChange={(e) => changeEvent([key, "link"], e.target.value)}
          />
        </div>
        <div className={"label_combo"}>
          <label htmlFor={`event_location_${key}`}>Ort</label>
          <input
            name={`event_location_${key}`}
            type="text"
            value={data.location}
            className=""
            onChange={(e) => changeEvent([key, "location"], e.target.value)}
          />
        </div>
        <div>
          <button onClick={() => deleteEvent(key)}>Löschen</button>
        </div>
      </div>,
    );
  });

  return (
    <div className="adminPanel">
      {loggedIn ? (
        <div className={"content"}>
          <div className="tabBar">
            <button
              className={`tab ${editingMode === "events" && "active"}`}
              onClick={() => setEditingMode("events")}
            >
              Veranstaltungen
            </button>
            <button
              className={`tab ${editingMode === "translations" && "active"}`}
              onClick={() => setEditingMode("translations")}
            >
              Übersetzungen
            </button>
          </div>

          {editingMode === "translations" && (
            <div className={"fullWidth"}>
              <table>
                <thead>
                  <tr>
                    <th width={120}></th>
                    <th>DE</th>
                    <th>EN</th>
                  </tr>
                </thead>
                <tbody>{renderedTranslations}</tbody>
              </table>
              <div className="status">
                {dirtyState ? (
                  "Speichert...."
                ) : (
                  <input
                    type="button"
                    value="add string"
                    className={"button"}
                    onClick={() => addLineToTranslations()}
                  />
                )}
              </div>
            </div>
          )}
          {editingMode === "events" && (
            <div className="events fullWidth">
              <h1>Veranstaltungen</h1>
              <div className="eventsList">{renderedEvents}</div>
              <div className="status">
                {dirtyState ? (
                  "Speichert...."
                ) : (
                  <input
                    type="button"
                    value="Neuer Event"
                    className={"button"}
                    onClick={() => addEvent()}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <form className="login" action="" onSubmit={(e) => login(e)}>
          <input
            type="text"
            name="username"
            placeholder="Benutzername"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Passwort"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input type="submit" value="Login" />
        </form>
      )}
    </div>
  );
}
