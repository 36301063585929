export function getBaseUrl(full = true, subDir = "", forceFull = false) {
  const appendix = "/";
  const serverUrl =
    process.env.NODE_ENV === "development" ||
    navigator.userAgent === "ReactSnap"
      ? "http://xpect.test"
      : "https://" + window.location.hostname;

  if (full) {
    if (navigator.userAgent === "ReactSnap" && forceFull === false) {
      return appendix + subDir;
    }
    return serverUrl + appendix + subDir;
  }

  return appendix + subDir;
}
